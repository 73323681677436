:root{
    --primary-color: rgb(213, 9, 101);
    --secondary-color: rgb(244, 247, 255);
}
body{
    background-color: $body-background;
    color: $secondary-color;
    letter-spacing: -0.01rem;
    .wrapper{
        position: relative;
        overflow: hidden;
        max-width: 100%;
        height: 100%;
        padding-top: 40px;
    }
    .container{
        max-width: 1346px;
        margin-left: auto;
        margin-right:auto;
        &.navbar{
            max-width: 1140px;
            padding: 0 15px;
            .navbar-actions{
                i{
                    font-size: 35px;
                }
                .canvas{
                    @media screen and (max-width: 1200px) {
                        margin-left: 30px;
                    }
                    @media screen and (max-width: 480px) {
                        margin-left: 20px;
                    }
                }
            }
            .navbar-actions{
                .icon-actionable a{
                    i {
                        border-radius: 50%;
                        background: $primary-color;
                        width: 50px;
                        height: 50px;
                        display: inline-block;
                        line-height: 46px;
                        text-align: center;
                        color: white;
                        @media screen and (max-width: 480px) {
                            width: 40px;
                            height: 40px;
                            line-height: 36px;
                            font-size: 27px;
                        }
                      }
                      &:hover i{
                        background-color: $primary-color-light;
                      }
                }
                .icon-actionable{
                    @media screen and (max-width: 480px) {
                        align-self: center;
                    }
                }
                .icon-he.waving-hand::before {
                    animation-name: wave-animation;
                    animation-duration: 3.5s;
                    animation-iteration-count: infinite;
                    transform-origin: 50% 70%;
                    display: inline-block;
                }
                @keyframes wave-animation {
                    0% {
                      transform: rotate(0deg);
                    }
                    20% {
                      transform: rotate(0deg);
                    }
                    30% {
                      transform: rotate(10deg);
                    }
                    40% {
                      transform: rotate(-20deg);
                    }
                    50% {
                      transform: rotate(10deg);
                    }
                    60% {
                      transform: rotate(-20deg);
                    }
                    70% {
                      transform: rotate(10deg);
                    }
                    80% {
                      transform: rotate(0);
                    }
                    100% {
                      transform: rotate(0);
                    }
                  }
            }
            @media screen and (max-width: 1200px) {
                max-width: 90%;
            }
            #site-logo{
                @media screen and (max-width: 400px) {
                    max-width: 160px;
                }
            }
        }
        @media screen and (max-width: 1400px) {
            max-width: 90%;
        }
    }
    .navbar-menu{
        ul{
            list-style: none;
            margin:0;
            column-gap: 30px;
            li{
                padding: 16px 16px 8px 15px;
                margin: 0;
                a{
                    position: relative;
                    display: block;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 600;
                    color: #fff;
                    &:hover{
                        text-decoration: none;
                        color:$primary-color;
                    }
                }
            }
            &.uppercased li a span{
                text-transform: uppercase;
                letter-spacing: 0.25px;
            }
        }
    }
    header{
        &.is-fixed {
            position: fixed;
            padding: 0;
            top: -100%;
            left: 0;
            width: 100%;
            box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
            z-index: 9999;
            background: $body-background;
            background: linear-gradient(0deg, $body-background 0%, darken($body-background,6) 100%);
            border: none;
            opacity: 1;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
        }
        &.is-fixed.is-small {
            top: 0;
            height: 87px;
            .container.navbar {
                padding: 15px;
            }
        }
        & > .navbar{
            align-items: center;
            column-gap: 20px;
            & > *{
                display: inline-flex;
            }
        }
        .main-logo img {
            max-height: 55px;
        }
        .canvas-nav-wrap {
            .overlay-canvas-nav {
                content: '';
                position: fixed;
                right: 0%;
                top: 0;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%);
                width: 100%;
                height: 100%;
                z-index: 999;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            .inner-canvas-nav {
                width: 340px;
                height: 100%;
                top: 0;
                left: -340px;
                position: fixed;
                background-color: darken($body-background,6);
                z-index: 1000;
                overflow-y: auto;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }
        }
        .canvas-nav-wrap.active {
            .overlay-canvas-nav {
                opacity: 1;
                visibility: visible;
            }
            .inner-canvas-nav {
                left: 0;
            }
        }
        .inner-canvas-nav {
            .side-bar {
                padding: 30px 30px 10% 30px;
                position: relative;
                min-height: 100%;
                .canvas-nav-close {
                    position: absolute;
                    top: 50px;
                    right: 30px;
                    cursor: pointer;
                    line-height: 30px;
                }
                .mobile-main-nav {
                    margin-top: 50px;
                    ul.menu{
                        list-style: none;
                        margin: 0;
                        li{
                            a:link{
                                line-height: 50px;
                                font-size: 18px;
                                font-weight: 300;
                                color: var(--secondary-color);
                                width: 100%;
                                display: block;
                                border-bottom: 1px solid rgba($secondary-color,0.3);
                                text-decoration: none;
                                &:hover{ color: var(--primary-color) }
                            }
                        }
                    }
                }
            }
            i.close::before {
                font-style: normal;
                content: '\00d7';
                font-size: 40px;
                font-weight: 300;
                line-height: 25px;
                display: inline-block;
            }
            .bottom-sidenav{
                position: absolute;
                bottom: 30px;
                left: 30px;
                width: calc( 100% - 60px );
                img{ width: 24px; height: auto; margin-right: 12px; }
                p.extrasmall {
                    font-size: 12px;
                    line-height: 1;
                    margin-bottom: 15px;
                }
            }
        }
    }
    .section-title{
        font-size: 50px;
        font-weight: 600;
        color:$primary-color;
        line-height: 1;
        margin-bottom: 25px;
        small{
            display: block;
            color: lighten($body-background,25);
            line-height: 1.2;
            @media screen and (max-width: 1300px) {
                font-size: 70%;
            }
        }
        &.who{
            small{ 
                font-size:26px; margin-top: 5px; 
                b{ font-weight: 600; }
            }
        }
        @media screen and (max-width:480px) {
            font-size: 45px;
        }
    }
    .gradient-title{
        span {
            background-image: linear-gradient(to right top, $secondary-color, #e4c1ad, $primary-color-light, $primary-color);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    } 
    section.columns {
        align-items: center;
        p {
            margin-bottom: 0;
            font-weight: 300;
            font-size: 18px;
            text-align: justify;
            letter-spacing: 0.2px;
            & + p {
                margin-top: 15px;
            }
            b,strong{
                font-size: 110%;
            }
            & > *.text-primary{
                color: $primary-color-light
            }
        }
        >.column.content-col {
            padding-left: 5vw;
        }
        .compos-wrapper{
            padding:0 3vw;
            img.puffed{
                max-width: 33%; height: auto;
                &.xxs {
                    max-width: 22%;
                  }
                &.xs {
                    max-width: 24%;
                  }
                &.smaller {
                    max-width: 26%;
                  }
                &.bigger {
                    max-width: 40%;
                  }
            }
        }
        blockquote{
            margin: 15px 0;
            border-color: rgba(255,255,255,0.1);
            color: #d2d2d2;
            p{
                font-size: 15px;
            }
            cite{
                font-size: 15px;
                font-style: normal;
            }
            &.core{
                border-color: var(--primary-color);
                margin-bottom: 30px;
                color: var(--secondary-color);
            }
        }
        &.who-content{
            h3.section-title{
                margin-top: 35px;
                margin-bottom: 15px;
                font-size: 26px;
                small{
                    font-size: 21px;
                }
            }
        }
    } 
    .sections-wrapper .container > section.columns:not(:last-child){ margin-bottom: 60px; }
    .sections-wrapper.whos{ margin-top: -40px; }
    section .section-desc{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-bottom: 50px;
        padding-right:30px;
        h3.section-title{ 
            margin-bottom: 50px;
            @media screen and (max-width:1060px) {
                text-align: center;
                margin-bottom: 20px;
            }
        }
        p.tiny{ 
            margin:0;
            @media screen and (max-width:1060px) {
                text-align: center;
            } 
        }
        & + .column {
            @media screen and (max-width:480px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
    a.reviews-link{
        display: block;
        text-align: center;
        margin-top: 35px;
        text-decoration: underline;
        text-transform: lowercase;
        outline: none;
        font-size: 18px;
    }
    img.d_avatar{ margin-bottom: 25px;}
    .who-content{ align-items: center; }
    .who-content p:not(.big){
        font-weight: 300;
        font-size: 18px;
    }
    p.tiny{ font-size: 15px;}
    .mt-xl{ margin-top:100px }
    .mt-xxl{ margin-top:130px }
    .is-animated{
        &.xmove{
            -webkit-animation: xmove 5s infinite linear;
            animation: xmove 5s infinite linear;
        }
        &.xmove-r{
            -webkit-animation: xmove-r 5s infinite linear;
            animation: xmove-r 5s infinite linear;
        }
        &.xflip{
            -webkit-animation: xflip 4s infinite linear;
            animation: xflip 4s infinite linear;
        }
        &.spins {
            -webkit-animation: spinna 6s ease-in-out infinite alternate;
            animation: spinna 6s ease-in-out infinite alternate;
            }
    }
    .cs-pos{
        top: var(--pos-t, unset);
        bottom: var(--pos-b, unset);
        left: var(--pos-l, unset);
        right: var(--pos-r, unset);
    }
    .hero-full-title {
        padding-top: 80px;
        padding-bottom: 90px;
        position: relative;
        .bg-grid-line {
            width: 100%;
            height: 480px;
            overflow: hidden;
            perspective: 420px;
            position: absolute;
            bottom: 60px;
        }
        .bg-line {
            width: 100%;
            height: 100%;
            transform: rotateX(40deg);
            opacity: .2;
            overflow: hidden;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 200%;
                background-image: linear-gradient(to right,$secondary-color 0.8px,transparent 0),linear-gradient(to bottom,$secondary-color 0.8px,transparent 0);
                background-size: 40px 30px;
                background-repeat: repeat;
                animation: playline 35s linear infinite;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, $body-background 0%, rgba(22, 22, 22, 0.00) 40%, $body-background 100%);
            }
        }
    }
    .hero{
        &.hero-xs{
            padding-bottom: 1rem;
            padding-top: 1rem;
        }
        img.cs-pos{
            &.small{
                max-width: 2vw;
                height: auto;
                @media screen and (max-width:480px) {
                    max-width: 6vw;
                }
            }
            &.big{
                max-width: 3.5vw;
                height: auto;
                @media screen and (max-width:480px) {
                    max-width: 7.5vw;
                }
            }
            &.xl{
                max-width: 4vw;
                height: auto;
                @media screen and (max-width:480px) {
                    max-width: 6vw;
                }
            }
            &.xxl{
                max-width: 4.75vw;
                height: auto;
                @media screen and (max-width:480px) {
                    max-width: 9vw;
                }
            }
        }
        .hero-body.tight{
            margin: 0 auto;
            max-width: 790px;
            padding-top: 0;
            padding-bottom: 0;
            @media screen and (max-width:870px) {
                max-width: 80%;
            }
            @media screen and (max-width:480px) {
                max-width: 102%;
                padding-left: 5%;
                padding-right: 5%;
            }
            h1.title{
                font-size: 2.5rem;
                font-weight: 600;
                letter-spacing: -0.03rem; 
                text-align: center;
                line-height: 1;
                margin-bottom: 20px;
                padding: 0 2vw;
                @media screen and (max-width:870px) {
                    font-size: 2rem;
                }
                @media screen and (max-width:480px) {
                    font-size: 1.5rem;
                    line-height: 1.2;
                }
                @media screen and (max-width: 400px) {
                    font-size: 1.25rem;
                }
            }
            p{
                max-width: 90%;
                text-align: justify;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 0;
                line-height: 1.2;
                font-weight: 300;
                margin-top: 30px;
            }
        }
    }
    h2.subtitle {
        font-size: 26px;
        @media screen and (max-width:480px) {
            font-size: 20px;
        }
    }
    h3.subtitle {
        font-size: 23px;
    }
    h3.section-title{
        font-size: 40px;
        line-height: 1;
        &.text-tertiary{ color: $tertiary-color }
        small{ margin-top: 8px ;}
    }
    .typewriter{
        span{ vertical-align: top; line-height: 30px; position: relative; }
        display: inline-block;
        line-height: 1;
        margin: 0;
        padding: 12px 0px 4px 26px;
        position: relative;
        &.monoline{ padding: 1px 10px 5px 11px }
        &::before{
            content:'';
            display: block;
            position: absolute;
            top:0; right:0;
            height:100%;
            width:100%;
            background-color: $primary-color;
            background: linear-gradient(90deg,$primary-color 0%, $primary-color-dark 100%);
            -webkit-animation: scale-in-x 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2.5s both;
                animation: scale-in-x 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 2.5s both;
        }
    }
    .typing {
        display: inline-block;
        width: calc( var(--chars) * 1ch );
        animation: typing 2s steps(var(--chars)), blink .5s step-end infinite alternate;
        white-space: nowrap;
        overflow: hidden;
        border-right: 2px solid $primary-color;
    }
    //cards
    .card-box {
        background: linear-gradient(89.96deg,hsla(0,0%,100%,.05) .03%,hsla(0,0%,100%,.008) 49.67%,hsla(0,0%,100%,.05) 99.96%);
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        border-radius: 8px;
        position: relative;
        border: 1px solid hsla(0,0%,100%,.1);
        margin-bottom: 30px;
        padding: 19px;
        .image {
            border-radius: 10px;
            overflow: hidden;
            margin: 0;
            width: 100%;
            a {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .5s ease-in-out;
                }
            }
        }
        .content {
            position: absolute;
            padding: 4px 11px 8px 11px;
            bottom: 30px;
            left: 30px;
            right: 30px;
            background: hsla(0,0%,100%,.1);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
            text-align: center;
            border-radius: 11px;
            &:hover {
                backdrop-filter: unset;
            }
            a.card-title {
                font-size: 17px;
                font-weight: bold;
                color: $secondary-color;
                &:hover{ text-decoration: none; color: $body-background }
            }
        }
        &:hover {
            .image {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }    
    .container.tight {
        max-width: 1000px;
        @media screen and (max-width:1060px) {
            max-width: 95%;
        }
    }
    .flex-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    ul.inline{
        margin: 0;
        list-style: none;
        li{ display: inline-block; }
    }
    & > .wrapper{
        background-image: url('../images/foor_bg.webp');
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        @media screen and (max-width:870px) {
            background-size: 140%;
        }
    }
    .text-light{ font-weight: 300; }
    .gradient-position{
        height: 100vh;
        left: 0;
        mix-blend-mode: overlay;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
        img{
            filter: blur(80px);
            position: fixed;
            max-width: 400px;
        }
        .left{
            left: -4%;
            top: -4%;
        }
        .right{
            right: -4%;
            bottom: -4%;
        }
    }
    .pagination{
        padding: 0;
        margin-top: 50px;
        margin-bottom: 0;
        @media screen and (max-width: 480px) {
            display: none;
        }
    }
    .pagination .page-item {
        padding: 0 25px;
    }
    .reviews-wrapper{
        margin-bottom: 50px;
        background-color: $body-background;
        @media screen and (max-width:1060px) {
            margin-bottom: -30px;
            padding-bottom: 90px;
        }
    }
    .reviews,.reviews-list{
        .revy-slide {
            padding: 0 15px;
        }
        .testimonial-item {
            position: relative;
            background: linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0) 51.68%);
            padding: 40px 40px 0;
            border-radius: 20px;
            border: 0.5px solid rgba(255,255,255,.05);
            border-bottom-width: 0;
            p {
                margin:0;
                font-size: 14px;
                & + p{ margin-top: 6px;}
              }
          }                
          .testimonial-header {
            margin-bottom: 25px;
            text-align: center;
            span {
                display: block;
                line-height: 1;
                font-size: 12px;
                color:$primary-color-light;
            }
            .reviewer {
              font-size: 15px;
              font-weight: 600;
              margin-bottom: 10px;
              text-transform: uppercase;
              color:white;
              &::before {
                content: '❝';
                display: block;
                font-size: 35px;
                margin-bottom: 10px;
                opacity: 0.5;
              }
            }
          }          
    }
    .privacy-box{
        background: linear-gradient(180deg,rgba(255,255,255,.05),rgba(255,255,255,0) 51.68%);
        padding: 40px 40px 0;
        border-radius: 20px;
        border: .5px solid rgba(255,255,255,.05);
        border-bottom-width: 0;
        span.subtitle{
            font-weight: 600;
            text-transform: uppercase;
            font-size: 16px;
            text-align: center;
            margin-bottom: 15px;
            display: block;
        }
        p{
            font-size: 18px !important;
            margin-bottom: 20px !important;
        }
    }
    .footer{
        p {
            font-size: 13px;
            max-width: 400px;
            margin: 0 auto;
            text-align: center;
        }
        .copyright p{
            max-width: none;
            text-align: inherit;
            margin: unset;
        }
        .copyright ul.inline{
            @media screen and (max-width:1060px) {
                margin-right: 40px;
            }
            li{ 
                vertical-align: top; line-height: 15px; 
                span{ margin-right:15px; display: inline-block; }
                a{
                    img{
                        width: 20px;
                        height: auto;
                        vertical-align: text-bottom;
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
        } 
        ul.inline a{ font-size: 14px; }
        span.smallest{ font-size: 11px;}
        .copyright div.column {
            @media screen and (max-width: 480px) {
                text-align: left;
                margin-top: 10px;
            }
        }
    } 
    i[class^="icon-"]:before {
        font-family: "DGIcons";
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .icon-wa:before { content: '\e818'; }
    .icon-ms:before { content: '\e823'; }
    .icon-mh:before { content: '\e821'; }
    .icon-mv:before { content: '\e808'; }
    .icon-mf:before { content: '\e806'; }
    .icon-mf:hover:before { content: '\e808'; }
    .icon-ma:before { content: '\e81f'; }
    .icon-he:before { content: '\e822'; }
    .prefooter.contactme {
        padding: 110px 0;
        background: linear-gradient(0deg, rgba(39,39,42,1) 0%, rgba(39,39,42,0) 100%);
        .contactme-wrapper{
            background: linear-gradient(264.28deg,lighten($primary-color,25) -38.2%,darken($primary-color,12) 103.12%);
            color: $secondary-color;
            border-radius: 26px;
            padding: 37px 43px;
            position: relative;
            h4{
                margin: 0;
                font-size: 32px;
                @media screen and (max-width: 480px) { font-size: 28px; }
                @media screen and (max-width: 400px) { font-size: 23px; }
                b{ display: block; }
            }
            p{
                margin: 20px 0;
                font-size: 15px;
                @media screen and (max-width: 480px) { font-size: 13px; }
            }
            .flex-list {
                margin-right: -50px;
                gap: 10px;
                .contact-button{
                    max-width: calc(50% - 40px);
                    padding: 7px 15px;
                    background: rgba(0,0,0,.06);
                    border: 1px solid rgba(255,255,255,0.1);
                    border-radius: 11px;
                    @media screen and (max-width:870px) {
                        max-width: 100%;
                        width: 100%;
                    }
                    a {
                        color: white;
                        font-size: 13px;
                        display: flex;
                        flex-direction: row;
                        column-gap: 12px;
                        align-items: center;
                        line-height: 1.25;
                        text-decoration: none;
                        i {
                            display: block;
                            text-align: center;
                            font-size: 45px;
                            line-height: 1;
                            width: 60px;
                            margin-bottom: 3px;
                            &.avatar{
                                width: 46px;
                                min-width: 46px;
                                height: 46px;
                                background-image: url('../images/avatar_pic.webp');
                                background-repeat: no-repeat;
                                background-size: 100%;
                                border-radius: 100%;
                            }
                          }
                        &#bot-contact-f:hover{ cursor:pointer }
                      }
                }
                &.md-vertical{
                    @media screen and (max-width:870px) {
                        flex-direction: column;
                        align-items: start;
                        margin-right: 0;
                    }
                }
            }
            .interactive-compos-col {
                position: relative;
                margin-top: -37px;
                margin-bottom: -37px;
                .interactive-compos{
                    position: absolute;
                    right: -43px;
                    bottom: 0;
                    img{
                        &.section-image{ z-index: 1; position: relative; margin-left: auto; }
                        &.el2{ max-width: 75%; z-index: 0; }
                        &.el1{ max-width: 12%; z-index: 2; }
                    }
                }
                @media screen and (max-width:480px) {
                    padding-top: 100%;
                    margin-top: 0;
                }
            }
            @media screen and (max-width: 480px) {
                padding: 27px 33px 37px 33px;
            }
        }
        .container.tight{
            @media screen and (max-width: 480px) {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }
    //cursor
    .cs-mouse {
        position: fixed;
        left: 0;
        top: 0;
        pointer-events: none;
        border-radius: 50%;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        visibility: hidden;
    }
    .cs-mouse-inner {
        margin-left: -5px;
        margin-top: -5px;
        width: 8px;
        height: 8px;
        z-index: 10000;
        background-color: $primary-color;
        -webkit-transition: width .5s ease-in-out, height .5s ease-in-out, margin .5s ease-in-out, opacity .5s ease-in-out;
        transition: width .5s ease-in-out, height .5s ease-in-out, margin .5s ease-in-out, opacity .5s ease-in-out;
        &.mouse-hover {
            margin-left: -40px;
            margin-top: -40px;
            width: 80px;
            height: 80px;
            background-color: $primary-color;
            opacity: .3;
        }
    }
    .cs-mouse-outer {
        margin-left: -22px;
        margin-top: -22px;
        width: 40px;
        height: 40px;
        border: 2px solid $primary-color;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        z-index: 10000;
        opacity: .5;
        -webkit-transition: all .05s ease-out;
        transition: all .05s ease-out;
        &.mouse-hover {
            opacity: 0;
        }
    }
    //logo footer animation
    .logo_footer{
        max-width: 500px;
        width: 100%;
        margin: 0 auto 50px auto;
    }
    .logo_footer #linea-1 {
        stroke-dasharray: 600;
        stroke-dashoffset: 600;
        pointer-events: none;
    }
    .logo_footer #linea-2 {
        stroke-dasharray: 600;
        stroke-dashoffset: 600;
        pointer-events: none;
    }
    .logo_footer #cerchio {
        opacity: 0;
        pointer-events: none;
    }
    .logo_footer #payoff {
        opacity: 0;
        pointer-events: none;
    }
        @keyframes lineauno {
            0% {
            stroke-dashoffset: -600;
            }
            60%,100% {
                stroke-dashoffset: 0;
            }
        }
        @keyframes lineadue {
            0% {
            stroke-dashoffset: 600;
            }
            60%,100% {
                stroke-dashoffset: 0;
            }
        }
        @keyframes appear {
            from{ opacity:0 }
            to{ opacity:1 }
        }
        .logo_footer.draw {
            #cerchio {
                animation: appear 2s linear;
                animation-iteration-count: 1;
                animation-delay: 0s;
                animation-fill-mode: forwards;
            }
            #linea-1 {
                animation: lineauno 4s linear;
                animation-iteration-count: 1;
                animation-delay: 1.2s;
                animation-fill-mode: forwards;
            }
            #linea-2 {
                animation: lineadue 4s linear;
                animation-delay: 3.75s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
            #payoff {
                animation: appear 2s linear;
                animation-delay: 3.75s;
                animation-iteration-count: 1;
                animation-fill-mode: forwards;
            }
        }
    //progress
    .progress-wrap {
        position: fixed;
        bottom: 30px;
        right: 30px;
        height: 44px;
        width: 44px;
        cursor: pointer;
        display: block;
        border-radius: 30px;
        -webkit-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
        box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        -webkit-transition: all 400ms linear;
        -o-transition: all 400ms linear;
        transition: all 400ms linear;
        background: $body-background;
        &::after {
            position: absolute;
            content: '\02039';
            transform: rotate(90deg);
            text-align: center;
            line-height: 34px;
            font-size: 38px;
            color: $primary-color-dark;
            left: 0;
            top: 0;
            height: 44px;
            width: 44px;
            cursor: pointer;
            display: block;
            z-index: 1;
            -webkit-transition: all 400ms linear;
            -o-transition: all 400ms linear;
            transition: all 400ms linear;
        }
        svg {
            path {
                fill: none;
            }
        }
        svg.progress-circle {
            path {
                box-sizing: border-box;
                stroke: $primary-color-dark;
                stroke-width: 5;
                transition-property: all;
                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                transition-duration: 150ms;
                transition-duration: 0.4s;
                transition-timing-function: linear;
            }
        }
    }
    .progress-wrap.active-progress {
        opacity: 1;
        visibility: visible;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    .show-l{
        display: none;
        @media screen and (max-width: 1200px) {
            display: block;
        }
    }
    .hide-l{
        @media screen and (max-width: 1200px) {
            display: none !important;
        }
    }
    &.web{
        @media screen and (max-width: 400px) {
            h2.subtitle{ font-size: 17px;} 
            .section-title{ font-size: 36px; }
        }
    }
    &.grafiche{
        @media screen and (max-width: 480px) {
            .section-title{ font-size: 36px; }
        }
        @media screen and (max-width: 400px) {
            h2.subtitle{ font-size: 17px;}
        }
    }
    &.chi-sono{
        @media screen and (max-width: 860px) {
            img.d_avatar.img-responsive{
                max-height: 500px;
                margin: 60px auto 0 auto;
            }
        }
        @media screen and (max-width: 400px) {
            h2.subtitle{ font-size: 17px;}
        }
    }
    &.recensioni{
        @media screen and (max-width: 480px) {
            .reviews-list.full .testimonial-item span.question{
                line-height: 1.6;
                display: inline-block;
                margin-bottom: 5px;
            }
        }
        @media screen and (max-width: 400px) {
            h2.subtitle{ font-size: 17px;}
        }
    }
    @media screen and (max-width: 870px) {
        .md-order-1{ order: 1;}
        .md-order-2{ order: 2;}
    }
}