* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }
b,strong {
  font-weight: 400; }
img {
  display: block;
  max-width: 100%;
  height: auto; }
ul, ol {
  padding: 0; }
ul li, ol li {
  margin: 0; }
h2,h3,h4 {
  font-weight: 300; }
a {
  text-decoration: none;
  transition: all .2s linear; }
svg, svg path {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none; }

  &.dragging {
    cursor: pointer;
    cursor: hand; } }

.title {
	font-size: 2.5rem;
	font-weight: 600;
	letter-spacing: -0.03rem;
	text-align: center;
	line-height: 1 {
  	margin-bottom: 0; } }
.image {
	width: 500px;
	margin-top: 3%;
	box-shadow: 1px 0 10px rgba(0,0,0,0.3); }

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before {
    display: table;
    content: ''; }

  &:after {
    display: table;
    content: '';
    clear: both; } }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide {
  img {
    display: block; }

  &.slick-loading img {
    display: none; }

  &.dragging img {
    pointer-events: none; } }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
