// Animations
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.rt{
  -webkit-animation-name: loading;
  animation-name: loading;
  -webkit-animation-duration: 13s;
  animation-duration: 13s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important; 
}
.rtv{
  -webkit-animation-name: loading;
  animation-name: loading;
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-$unit-8);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes playline {
  0% {
      transform: translate3d(0,-50%,0) 
  }

  100% {
      transform: translateY(0,0,0) 
  }
}

@keyframes typing {
  from {
    width: 0
  }
}
    
@keyframes blink {
  50% {
    border-color: transparent
  }
}

 @-webkit-keyframes scale-in-x {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-x {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes ymove {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
}

@keyframes xmove {
  0%, 100% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(-20px);
  }
}
@keyframes xmove-r {
  0%, 100% {
      transform: translateX(0);
  }
  50% {
      transform: translateX(20px);
  }
}

@keyframes spin {
  0%,15% {
      transform:rotate(0deg);
  }
  85%,100% {
      transform:rotate(360deg);
  }
}


@keyframes xflip {
  30%, 100% {
    transform: rotateY(180deg)
  }
}

@keyframes spinna {
  0% {
    transform: rotate(-45deg);
  }
  20%,40% {
    transform: rotate(0);
  }
  60% {
    transform: rotate(45deg);
  }
  80%,100% {
    transform: rotate(0);
  }
}

.puffIn {
  animation-name: puffIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@-webkit-keyframes puffIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

@keyframes puffIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(2px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

.vanishIn {
  animation-name: vanishIn;
  animation-duration: 1s;
  animation-fill-mode: both;
}

@-webkit-keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@-webkit-keyframes pulse {
  from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
  }

  50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
  }

  50% {
      -webkit-transform: scale3d(1.05, 1.05, 1.05);
      transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
  }
}


.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}

 @-webkit-keyframes jello-horizontal {
  25% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  50% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  80% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  30% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  40% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  50% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  70% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

.jelly {
  -webkit-animation-name: jello-horizontal;
  animation-name: jello-horizontal;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}

@-webkit-keyframes magicw {
	0%,20% { 	transform: rotate(0deg) translateX(0) rotate(0deg); }
  40% { 	transform: rotate(30deg) translateX(30px) rotate(-30deg); }
	70%   {  transform: rotate(320deg) translateX(30px) rotate(-320deg); }
  86%,100%   {  transform: rotate(360deg) translateX(0) rotate(-360deg); }
}

@keyframes magicw {
	0%,20% { 	transform: rotate(0deg) translateX(0) rotate(0deg); }
  40% { 	transform: rotate(30deg) translateX(30px) rotate(-30deg); }
	70%   {  transform: rotate(320deg) translateX(30px) rotate(-320deg); }
  86%,100%   {  transform: rotate(360deg) translateX(0) rotate(-360deg); }
}

@keyframes breath {
  0%,
  to {
    transform:translateY(0) rotate(0)
  }
  50% {
    transform:translateY(4%) rotate(3deg)
  }
}
@keyframes roat {
  0%,
  to {
    transform:rotate(0) translateX(0) translateY(0)
  }
  50% {
    transform:rotate(-2deg) translateX(-7px) translateY(11px)
  }
}

.megic {
  -webkit-animation: magicw 5s linear infinite;
  animation: magicw 5s linear infinite;
  -webkit-animation-duration: 5s !important;
  animation-duration: 5s !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}
.movey {
  -webkit-animation: ymove 2s linear infinite;
  animation: ymove 2s linear infinite;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}
.floaty {
  -webkit-animation: ymove 4s linear infinite;
  animation: ymove 4s linear infinite;
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}
.roaty {
  -webkit-animation: roat 4s linear infinite;
  animation: roat 4s linear infinite;
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}
.movex {
  -webkit-animation: xmove 4s linear infinite;
  animation: xmove 4s linear infinite;
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}
.movexr {
  -webkit-animation: xmove-r 4s linear infinite;
  animation: xmove-r 4s linear infinite;
  -webkit-animation-duration: 4s !important;
  animation-duration: 4s !important;
  -webkit-animation-delay: .2s !important;
  animation-delay: .2s !important;
}

.breathy {
  -webkit-animation:breath 6s linear 0s infinite;
  animation:breath 6s linear 0s infinite;
}
