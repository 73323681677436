@charset "utf-8";

// Import Font
@import "base/fonts";

// Set brand colors
$primary-color: rgb(213, 9, 101);
$secondary-color: rgb(244, 247, 255);
$body-background: rgb(39, 39, 42);

$tertiary-color: lighten($body-background,25);
.text-tertiary{ color: $tertiary-color }

// Update global variables
$base-font-family: 'Kross';
$fallback-font-family: "Helvetica Neue", sans-serif;
$html-font-size: 22px;
$html-line-height: 1.5;
$font-size: 1rem;

// Update component variables


// Import only what you need from Spectre
//Variables and mixins
@import "spectre/variables";
@import "spectre/mixins";
// Reset and dependencies
@import "spectre/normalize";
@import "spectre/base";
// Elements
@import "spectre/typography";
@import "spectre/buttons";
@import "spectre/media";
// Layout
@import "spectre/layout";
@import "spectre/hero";
@import "spectre/navbar";
// Components
@import "spectre/breadcrumbs";
@import "spectre/cards";
@import "spectre/pagination";
@import "spectre/off-canvas";
// Utility classes
@import "spectre/animations";
@import "spectre/utilities";