 :root {
  --bot-height: 40vh;
  --bot-width: 300px;
  --bot-avatar: url('../images/avatar_pic.webp'); }

#bot-contact:hover {
    cursor: pointer; }

#bot-wrapper {
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 8px;
  padding: 0.5rem;
  height: var(--bot-height);
  width: var(--bot-width);
  position: fixed;
  bottom: 100px;
  right: 20px;
  background: linear-gradient(89.96deg,rgba(255,255,255,.10) .03%,rgba(255,255,255,.016) 49.67%,rgba(255,255,255,.10) 99.96%);
  @media screen and (max-width: 480px) {
    height: 60vh;
    left: 20px;
    right: unset;
    bottom: 20px;
    z-index: 110; } }

#bot-container {
  border: 0;
  box-shadow: 1px 0 10px rgba(0,0,0,.3);
  border-radius: 8px;
  padding: 1rem 1rem 2.5rem 1rem;
  position: relative;
  height: 100%;
  background: rgba($body-background, 0.9);
  @media screen and (max-width: 480px) {
    background: rgba($body-background, 0.96); }

  #bot-inner {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
    padding-bottom: 15px;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none; } }
  .chat-response,
  .chat-ask {
    opacity: 0;

    /* Set to activated to show */
    transform: translateY(-50%);

    /* Set to activated to move down */
    transition: all 0.3s 0.3s;
    border-radius: 12px;
    background-color: hsl(0, 0%, 90%);
    padding: 0.5rem 0.7rem;
    line-height: 1.2;
    font-size: 16px;
    color: black;
    width: 80%;
    margin-bottom: 0.5rem; }
  .chat-response {
    margin-left: 30px;
    position: relative;
    padding: 8px 15px 12px 28px;
    line-height: 1.2;
    color: #fff;
    background-color: #c0085b;

    &:before {
      display: block;
      content: '';
      width: 46px;
      height: 46px;
      position: absolute;
      border: 1px solid #c0085b;
      left: -30px;
      top: 6px;
      background-image: var(--bot-avatar);
      background-color: #999;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 100%; } }
  .chat-ask {
    background-color: #000;
    margin-right: 0;
    margin-left: auto;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 3px 12px 4px 12px;
    border-radius: 6px;
    text-align: right;
    width: fit-content;
    max-width: 80%; }
  .choices {
    opacity: 0;

    /* Set to active to show */
    transform: translateY(-50%);

    /* Set to activated to move down */
    transition: all 0.3s 0.3s;
    transition: opacity 0.3s 0.3s;
    margin-top: 0.5rem;
    margin-left: 22px; }
  .choice {
    display: block;
    outline: none;
    border: 0;
    padding: 3px 12px 4px 12px;
    color: $body-background;
    text-align: left;
    font-weight: 600;
    background-color: $secondary-color;
    border-radius: 6px;
    font-size: 13px;
    line-height: 1.3;
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $primary-color; }
    &:disabled {
        opacity: 0.4;
        color: #909090; } }
  input.choice {
    cursor: text;
    width: 100%;
    padding: 5px 12px 6px 12px; }

  input.choice.choice-input.error {
    border: 2px solid darkred;
    background-color: pink; }

  .activated {
    opacity: 1;
    transform: translateY(0); }

  .bottom-actions {
    position: absolute;
    bottom: 1rem;
    right: 0.5rem;
    text-align: right;
    button:not(:last-child) {
        margin-right: 10px; } }
  .restart,
  .close {
    outline: none;
    font-size: 12px;
    display: inline-block;
    color: hsl(0, 0%, 50%);
    box-shadow: none;
    border: 0;
    border-radius: 1rem;
    background: hsl(0, 0%, 100%);
    padding: 0.2rem 0.5rem;
    cursor: pointer; } }

#bot-wrapper > a:link {
    font-size: 7px;
    color: silver;
    position: absolute;
    left: 1rem;
    bottom: 0.75rem; }
