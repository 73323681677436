@font-face {
    font-family: 'Kross';
    src: url('./fonts/kross/KrossNeueGrotesk-Light.eot');
    src: url('./fonts/kross/KrossNeueGrotesk-Light.eot?#iefix') format("embedded-opentype"), url('./fonts/kross/KrossNeueGrotesk-Light.woff2') format('woff2'), url('./fonts/kross/KrossNeueGrotesk-Light.woff') format("woff");
    font-weight: 300;
    font-style: normal; }

@font-face {
    font-family: 'Kross';
    src: url('./fonts/kross/KrossNeueGrotesk-Regular.eot');
    src: url('./fonts/kross/KrossNeueGrotesk-Regular.eot?#iefix') format("embedded-opentype"), url('./fonts/kross/KrossNeueGrotesk-Regular.woff2') format('woff2'), url('./fonts/kross/KrossNeueGrotesk-Regular.woff') format("woff");
    font-weight: 400;
    font-style: normal; }

@font-face {
    font-family: 'Kross';
    src: url('./fonts/kross/KrossNeueGrotesk-Bold.eot');
    src: url('./fonts/kross/KrossNeueGrotesk-Bold.eot?#iefix') format("embedded-opentype"), url('./fonts/kross/KrossNeueGrotesk-Bold.woff2') format('woff2'), url('./fonts/kross/KrossNeueGrotesk-Bold.woff') format("woff");
    font-weight: 600;
    font-style: normal; }

@font-face {
    font-family: 'DGIcons';
    src: url('./fonts/icons/dgraphic-icons.eot');
    src: url('./fonts/icons/dgraphic-icons.eot?#iefix') format("embedded-opentype"), url('./fonts/icons/dgraphic-icons.woff2') format('woff2'), url('./fonts/icons/dgraphic-icons.woff') format("woff"), url('./font/icons/dgraphic-icons.svg#dgraphic-icons') format('svg');
    font-weight: normal;
    font-style: normal; }
